<template>
  <div class="scale-main">
    <div
      v-for="(item, index) in data"
      :key="index"
      class="data-item"
      style="font-family: DIN Alternate"
    >
      <div class="number">
        <span class="num">{{ item.number }}</span>
        <span class="unit" :class="index == 0 ? 'ml-10' : 'ml-3'">
          {{ item.unit }}
        </span>
      </div>
      <div class="test">{{ item.test }}</div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "./CompanyScale.scss";
</style>

<script>
export default {
  name: "companyScale",
  props: {
    data: {
      type: Array,
    },
  },
};
</script>
