<template>
  <div>
    <CaseIntroduce></CaseIntroduce>
    <CustomerComponent></CustomerComponent>
    <footerCom></footerCom>
    <reservationCom></reservationCom>
  </div>
</template>

<script>
import CaseIntroduce from "./components/CaseIntroduce.vue";
import CustomerComponent from "./components/CustomerComponent.vue";
import footerCom from "../../components/footerCom/index.vue";
import reservationCom from "../../components/reservationCom/index.vue";
export default {
  metaInfo: {
    title: "客户案例",
    meta: [
      {
        name: "keywords",
        content:
          "数据隐私，隐私合规，GDPR，法规风险案例，腾讯，特步，浙江财开，中建，青岛地铁，欢聚、阿里巴巴，中兴，优必选，青岛地铁",
      },
    ],
  },
  name: "caseIndex",
  components: {
    CaseIntroduce,
    CustomerComponent,
    footerCom,
    reservationCom,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
