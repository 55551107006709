<template>
  <div class="case-introduce-main">
    <div class="case-introduce-con">
      <div class="title">
        <span class="config-title">{{ config.title }} </span>
        <span>{{ config.name }} </span>
      </div>
      <div class="remake">
        {{ config.remake }}
      </div>
      <CompanyScale :data="config.scaleData"></CompanyScale>
      <CustomSwiper></CustomSwiper>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "./CaseIntroduce.scss";
</style>

<script>
import CompanyScale from "./CompanyScale";
import CustomSwiper from "../../../components/swiper/CustomSwiper.vue";
export default {
  name: "caseIntroduce",
  components: {
    CompanyScale,
    CustomSwiper,
  },
  data() {
    return {
      config: {
        title: "行业头部企业的共同选择",
        name: "iLaw合规",
        remake:
          "从初创团队到大型集团，助力来自各行各业的组织更便捷使用合规产品",
        scaleData: [
          {
            number: 15,
            unit: "年",
            test: "法律科技经验",
          },
          {
            number: 800,
            unit: "+",
            test: "法律/计算机双背景人才",
          },
          {
            number: 10,
            unit: "+",
            test: "法律科技产品",
          },
          {
            number: 4000,
            unit: "+",
            test: "律所客户",
          },
          {
            number: 300,
            unit: "+",
            test: "企业法务部",
          },
        ],
      },
    };
  },
};
</script>
