<template>
  <div class="scroll-list">
    <div class="scroll-container">
      <div class="scroll-item">
        <div class="xscroll xscroll50">
          <img
            class="scroll-img"
            :src="item"
            alt=""
            v-for="(item, index) in imgList"
            :key="index"
          />
        </div>
        <div class="xscroll xscroll50">
          <img
            class="scroll-img"
            :src="item"
            alt=""
            v-for="(item, index) in imgList"
            :key="index"
          />
        </div>
      </div>
      <div class="scroll-item">
        <div class="xscroll xscroll40">
          <img
            class="scroll-img"
            :src="item"
            alt=""
            v-for="(item, index) in imgList2"
            :key="index"
          />
        </div>
        <div class="xscroll xscroll40">
          <img
            class="scroll-img"
            :src="item"
            alt=""
            v-for="(item, index) in imgList2"
            :key="index"
          />
        </div>
      </div>
      <div class="scroll-item">
        <div class="xscroll xscroll30">
          <img
            class="scroll-img"
            :src="item"
            alt=""
            v-for="(item, index) in imgList3"
            :key="index"
          />
        </div>
        <div class="xscroll xscroll30">
          <img
            class="scroll-img"
            :src="item"
            alt=""
            v-for="(item, index) in imgList3"
            :key="index"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    const zte = require("../../images/newHome/zte.png");
    const zl = require("../../images/newHome/zl.png");
    const zjfd = require("../../images/newHome/zjfd.png");
    const ybx = require("../../images/newHome/ybx.png");
    const xyd = require("../../images/newHome/xyd.png");
    const tx = require("../../images/newHome/tx.png");
    const ts = require("../../images/newHome/ts.png");
    const tl = require("../../images/newHome/tl.png");
    const sqhy = require("../../images/newHome/sqhy.png");
    const qw = require("../../images/newHome/qw.png");
    const msxf = require("../../images/newHome/msxf.png");
    const mindray = require("../../images/newHome/mindray.png");
    const lz = require("../../images/newHome/lz.png");
    const joyy = require("../../images/newHome/joyy.png");
    const hw = require("../../images/newHome/hw.png");
    const gtzm = require("../../images/newHome/gtzm.png");
    const fc = require("../../images/newHome/fc.png");
    const dfhy = require("../../images/newHome/dfhy.png");
    const chnt = require("../../images/newHome/chnt.png");
    const bk = require("../../images/newHome/bk.png");
    const bjdh = require("../../images/newHome/bjdh.png");
    const bc = require("../../images/newHome/bc.png");
    const albb = require("../../images/newHome/albb.png");
    const jyzy = require("../../images/newHome/jyzy.png");

    const dljt = require("../../images/newHome/newLogo/dljt.png");
    const hnyc = require("../../images/newHome/newLogo/hnyc.png");
    const hrzc = require("../../images/newHome/newLogo/hrzc.png");
    const jd = require("../../images/newHome/newLogo/jd.png");
    const jyjt = require("../../images/newHome/newLogo/jyjt.png");
    const phyd = require("../../images/newHome/newLogo/phyd.png");
    const qddt = require("../../images/newHome/newLogo/qddt.png");
    const rczg = require("../../images/newHome/newLogo/rczg.png");
    const tb = require("../../images/newHome/newLogo/tb.png");
    const ydzn = require("../../images/newHome/newLogo/ydzn.png");
    const yjpz = require("../../images/newHome/newLogo/yjpz.png");
    const yjsgs = require("../../images/newHome/newLogo/yjsgs.png");
    const zjbj = require("../../images/newHome/newLogo/zjbj.png");
    const zyby = require("../../images/newHome/newLogo/zyby.png");
    return {
      imgList: [zte, zl, zjfd, ybx, xyd, tx, ts, tl, dljt, hnyc, hrzc, jd],
      imgList2: [
        sqhy,
        qw,
        msxf,
        mindray,
        lz,
        joyy,
        hw,
        jyzy,
        jyjt,
        phyd,
        qddt,
        rczg,
        tb,
      ],
      imgList3: [
        gtzm,
        fc,
        dfhy,
        chnt,
        bk,
        bjdh,
        bc,
        albb,
        ydzn,
        yjpz,
        yjsgs,
        zjbj,
        zyby,
      ],
    };
  },
};
</script>
<style scoped lang="scss">
@import "./index.scss";
</style>
