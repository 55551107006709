<template>
  <div class="customer-main">
    <div class="show">精品案例展示</div>
    <div class="carousel">
      <el-carousel
        :interval="5000"
        type="card"
        height="400px"
        class="carousel-wrap"
      >
        <el-carousel-item v-for="(item, index) in customerInfo" :key="index">
          <div class="carousel-container">
            <div class="carousel-container-left">
              <div class="carousel-container-left-head">
                <img
                  :src="item.logo"
                  alt=""
                  :style="{
                    height: `${item.class.height}`,
                    width: `${item.class.width}`,
                    marginRight: `${item.class.marginRight}`,
                  }"
                />
                <span class="tag" v-if="item.tag">{{ item.tag }}</span>
              </div>
              <div class="carousel-container-left-content">
                <div class="title">{{ item.totalDescribe }}</div>
                <div class="detail">{{ item.detail }}</div>
              </div>
            </div>
            <div class="carousel-container-right">
              <img :src="item.customerPic" alt="" class="company" />
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "./CustomerComponent.scss";
</style>
<style lang="scss">
.carousel-wrap {
  .el-carousel__container {
    height: 420px;

    .el-carousel__arrow {
      background: rgba(255, 255, 255, 1);
      color: rgba(0, 131, 201, 1);
    }

    .el-carousel__arrow--left:hover,
    .el-carousel__arrow--right:hover {
      background: rgba(227, 245, 255, 1);
    }

    .el-carousel__arrow--left {
      left: 55px;
    }

    .el-carousel__arrow--right {
      right: 165px;
    }

    .el-carousel__indicators {
      display: none;
    }
  }

  .el-carousel__container:before,
  .el-carousel__container:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 10;
    // background: rgba(233, 244, 255, 1);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #ffffff 50%);
    filter: blur(14px);
  }

  .el-carousel__container:before {
    /* 模糊区域向左扩展10px（加上自身宽度） */
    left: -150px;
    width: 200px;
    /* 模糊区域宽度 */
  }

  .el-carousel__container:after {
    /* 模糊区域向右扩展10px（加上自身宽度） */
    right: -20px;
    width: 200px;
    /* 模糊区域宽度 */
  }
}
</style>

<script>
export default {
  name: "customerComponent",
  components: {},
  data() {
    return {
      customerType: "tx",
      customerInfo: [
        {
          type: "tx",
          tag: "互联网行业",
          logo: require("../../../images/newHome/case/tx-icon.png"),
          class: { height: "56px", width: "208px", marginRight: "10px" },
          customerPic: require("../../../images/newHome/case/tx.png"),
          totalDescribe: "搭建腾讯的隐私合规数字化平台",
          detail:
            "iLaw为腾讯集团搭建了隐私合规数字化平台，借助高效便捷的合规信息化工具，打造完善的合规生态体系，“腾讯数据隐私合规治理实践”入选2022中国网络文明大会个人信息保护论坛个人信息保护创新实践案例。",
        },
        {
          type: "albb",
          tag: "互联网行业",
          class: { height: "32px", width: "186px", marginRight: "10px" },
          logo: require("../../../images/newHome/case/albb-icon.png"),
          customerPic: require("../../../images/newHome/case/albb.png"),
          totalDescribe: "为阿里巴巴数据隐私合规研究提供能力支撑",
          detail:
            "iLaw为阿里巴巴“技术+法律”体系化数据隐私合规研究平台提供部分研究能力支撑，以Pass平台服务为基础，全面打造境内外隐私政策合规。",
        },
        {
          type: "zjck",
          tag: "金融行业",
          class: { height: "56px", width: "56px", marginRight: "10px" },
          logo: require("../../../images/newHome/case/zjck-icon.png"),
          customerPic: require("../../../images/newHome/case/zjck.png"),
          totalDescribe: "搭建浙江财开法规风险案例库",
          detail:
            "为了进一步推进企业管理现代化，加强合规体系建设，统筹风控合规管理一体化，共谱“数智财开”蓝图，iLaw助力企业建立智能化法律法规库、风险案例库，将其作为企业运行的常态化支掌底座，有效保证企业经营活动的效益性、可靠性、合规性，形成具有财开特色、贴合业务需求的数智化国企合规建设领先实践。",
        },
        {
          type: "zx",
          tag: "互联网行业",
          class: { height: "34px", width: "128px", marginRight: "10px" },
          logo: require("../../../images/newHome/case/zte-icon.png"),
          customerPic: require("../../../images/newHome/case/zte.png"),
          totalDescribe: "开发建设中兴通讯数据合规隐私项目",

          detail:
            "在中兴一期数据合规建设基础上，iLaw助力企业实现内部终端隐私评审与数据合规系统对接及优化，达成内部数据合规管理一体化。同时产品打通对外隐私中心与数据合规系统，确保对外隐私建设及时更新，确保合规无忧。产品不局限于数据合规，支持对不同国家与地区、不同业务的风险评估，以及合规成熟度基准测试：如贸易合规、反商业贿赂等，同时根据相关业务数据和风险监测模型进行实时风险监测，并以“数据大屏”形式预警。",
        },
        {
          type: "ybx",
          tag: "高科技行业",
          class: { height: "56px", width: "146px", marginRight: "10px" },
          logo: require("../../../images/newHome/case/ybx-icon.png"),
          customerPic: require("../../../images/newHome/case/ybx.png"),
          totalDescribe: "为优必选提供合规风险评估系统服务",
          detail:
            "iLaw为优必选提供合规风险评估系统服务，服务过程中，iLaw评估企业数据处理对个人隐私的影响，为企业提供风险管理和追踪功能以及支持隐私影响保护评估、数据影响保护评估等多类型的评估工具。此项服务帮助深圳市优必选科技股份有限公司在CCIA数据安全工作委员会个人信息保护影响评估(PIA)专题工作中进入第一批PIA标识“一星级名单”。",
        },
        {
          type: "hr",
          tag: "",
          class: { height: "56px", width: "205px", marginRight: "10px" },

          logo: require("../../../images/newHome/case/hr-icon.png"),
          customerPic: require("../../../images/newHome/case/hr.png"),
          totalDescribe: "助力华润知识产权搭建知识产权保护集中云服务平台",
          detail:
            "近年来，数字技术催生知识产权行业的新业态、新模式。华润知识产权管理有限公司持续以构建知产保护生态平台为业务导向，贯通知识产权保护上下游，开展知识产权规范化管理、侵权风险防范、知产案件管理及维权服务市场化等工作，护航企业知识产权，并助力知识产权行业有益发展。为遵循知识产权法律法规发展趋势、满足企业信息管理及知识产权保护数字化转型需要，iLaw以助力华润知识产权的知产保护业务市场化为主要目标，结合现有系统能力，提供可落地解决办法。华润知识产权 x iLaw 共同打造知识产权保护集中云服务平台，一款集知产业务门户官网、侵权举报、维权案件管理、组织管理等模块为一体的知产保护管理平台，在帮助公司知产业务相关部门提高工作效率的同时，助力企业知产业务市场化与数字化建设的快速实行。",
        },
        {
          type: "zjyj",
          tag: "建筑行业",
          class: { height: "56px", width: "82px", marginRight: "10px" },

          logo: require("../../../images/newHome/case/zjyj-icon.png"),
          customerPic: require("../../../images/newHome/case/zjyj.png"),
          totalDescribe: "为中建一局搭建智能诉讼管理平台",
          detail:
            "iLaw为中建一局打造智能诉讼管理平台，平台打通权威机构立案系统，链接司法大数据，对于大标的额重点案件进行特别提醒和预管。其中，系统可视化报表可帮助企业法务部实时管控公司风险。",
        },
        {
          type: "qddt",
          tag: "交通运输行业",
          class: { height: "54px", width: "162px", marginRight: "10px" },

          logo: require("../../../images/newHome/case/qddt-icon.png"),
          customerPic: require("../../../images/newHome/case/qddt.png"),
          totalDescribe: "为青岛地铁集团搭建数字化管理平台",
          detail:
            "iLaw通过定制化开发为青岛地铁集团法治建设和法务管理赋能。加强操作和管理的标准化、流程化、系统化，系统可提供全量案件监控、实时更新和随时查询功能，同时具备风险监控实时预警、多维度案件报表以及案件全流程管控等功能，能够助力法务人员增强法律业务技能，提升集团的法律业务管理能力，创建多维度、全要素、前瞻性的法务运营新局面。",
        },
        {
          type: "tb",
          tag: "快销品行业",
          class: { height: "56px", width: "132px", marginRight: "10px" },

          logo: require("../../../images/newHome/case/tb-icon.png"),
          customerPic: require("../../../images/newHome/case/tb.png"),
          totalDescribe:
            "为特步集团打造数字化合同管理平台搭建全新数字化合同应用场景",
          detail:
            '随着数字化时代进程不断加快，特步集团聚焦业务发展，推动智慧创新创造，注重以数字化方式提升运营效率。iLaw在智能合同领域具有丰富的经验和技术优势，此次合作iLaw将基于智慧服务能力和数据价值挖掘能力，秉承"数据+智能"建设性思维，聚焦挖掘数据价值，通过合同全生命周期管理、履约节点把控和风险实时预知等功能，为特步打造出高质量、高水平的精益化合同管理解决方案，探索符合特步发展特色的合同管理新路径。',
        },
        {
          type: "dljt",
          tag: "交通运输行业",
          class: { height: "56px", width: "80px", marginRight: "10px" },

          logo: require("../../../images/newHome/case/dljt-icon.png"),
          customerPic: require("../../../images/newHome/case/dljt.png"),
          totalDescribe: "助力大连交投搭建诉讼案件管理平台",
          detail:
            "在数字经济的浪潮下，企业经营所处的法律环境日益复杂，如何有效实施法律事务管理，建立智能化法律事务管理体系，全面感知并有效控制法律风险是企业经营发展中亟需考虑的问题。针对此问题，iLaw合规依托十多年法律科技积累与深入的行业需求洞察，为大连市建设铁路交通投资有限公司定制了精准的数字化涉诉管理平台。平台基于企业发展实际情况与最高院立案系统最新司法数据库研发，依托法律科技大数据引擎，对企业涉诉信息进行全局监控并进行提醒及预警，动态生成涉诉风险及案件分析报告，帮助企业全方位解决涉诉难题。",
        },
        {
          type: "joyy",
          tag: "互联网行业",
          class: { height: "48px", width: "110px", marginRight: "10px" },
          logo: require("../../../images/newHome/case/joyy-icon.png"),
          customerPic: require("../../../images/newHome/case/joyy.png"),
          totalDescribe: "为欢聚时代定制专业法律门户",
          detail:
            '欢聚法务部希望搭建一个独立的法务门户系统，该系统能够协助法务处理日常工作，也能够跟踪风险，管理风险。对于整体法务系统需求，欢聚通过内部详尽的调研与沟通，梳理出十数项定制化功能模块;了解比对市面多个类型服务商支持能力，最终选定在“法务+数字化"建设中具有丰富建设经验的iLaw为其提供全流程的法务门户定制化解决方案，开展长期的框架协议合作，共同建设专属的法多门户系统，为法务工作进一步赋能。',
        },
        {
          type: "chnt",
          tag: "能源行业",
          class: { height: "56px", width: "110px", marginRight: "10px" },
          logo: require("../../../images/newHome/case/chnt-icon.png"),
          customerPic: require("../../../images/newHome/case/chnt.png"),
          totalDescribe: "为正泰安能提供GDPR合规咨询服务",
          detail:
            "正泰安能作为知名光伏产业公司，公司计划在欧洲开展业务，考虑到欧洲法域对于数据合规的强监管态势，以及在海外业务拓展的进程中，面临来自欧洲合作公司的数据合规要求，公司需要提前考虑应对GDPR下的数据合规要求。iLaw作为数据治理领域知名的服务商，为正泰安能提供GDPR合规咨询服务，以管理层面作为正泰安能业务出海视窗，以法律合规服务为核心，以法律科技系统为技术辅助手段，帮助企业搭建GDPR合规体系，助力其业务出海持续顺利开展。",
        },
      ],
    };
  },
};
</script>
